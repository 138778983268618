import { Button } from '@/components/ui/atoms/button'
import { SUBSCRIPTION } from '@/consts'
import type { IPublicTenderHighlighted } from '@/types/public.tenders'
import { useStore } from '@nanostores/react'
import { atom } from 'nanostores'
import { useEffect } from 'react'

const selectedAtom = atom<string>('most_popular')
const dataAtom = atom<Array<IPublicTenderHighlighted>>([])

export default function PublicTenderHighlights({
  most_popular,
  published,
  incoming,
}: {
  most_popular: Array<IPublicTenderHighlighted>
  published: Array<IPublicTenderHighlighted>
  incoming: Array<IPublicTenderHighlighted>
}) {
  const $selected = useStore(selectedAtom)
  const $data = useStore(dataAtom)

  useEffect(() => {
    if ($selected === 'most_popular') {
      return dataAtom.set(most_popular)
    }
    if ($selected === 'published') {
      return dataAtom.set(published)
    }
    if ($selected === 'incoming') {
      return dataAtom.set(incoming)
    }
  }, [most_popular, incoming, published, $selected])

  return (
    <section className="flex flex-col max-w-screen-xl mx-auto  px-3 py-8 sm:py-12">
      <div className="mx-auto mb-8 max-w-2xl lg:text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Concursos
        </h2>
      </div>
      <div className="flex overflow-x-auto items-center justify-center text-center mb-8">
        <div
          className={`
          cursor-pointer text-sm font-semibold rounded p-2 sm:m-2
          ${$selected === 'most_popular' ? 'bg-orange-200 text-orange-900' : ''}
        `}
          aria-label="mais populares"
          onClick={() => selectedAtom.set('most_popular')}
          onKeyDown={() => {}}
        >
          Mais populares
        </div>
        <div
          className={`
          cursor-pointer text-sm font-semibold rounded p-2 sm:m-2
          ${$selected === 'published' ? 'bg-orange-200 text-orange-900' : ''}
        `}
          aria-label="Edital Publicado"
          onClick={() => selectedAtom.set('published')}
          onKeyDown={() => {}}
        >
          Edital Publicado
        </div>
        <div
          className={`
          cursor-pointer text-sm font-semibold rounded p-2 sm:m-2
          ${$selected === 'incoming' ? 'bg-orange-200 text-orange-900' : ''}
        `}
          aria-label="Edital próximo"
          onClick={() => selectedAtom.set('incoming')}
          onKeyDown={() => {}}
        >
          Edital Próximo
        </div>
      </div>
      <div className="flex flex-col overflow-hidden items-center sm:gap-2 sm:grid sm:grid-cols-2 lg:grid-cols-4 space-y-3 sm:space-y-0">
        {$data?.map((item) => (
          <a
            href={`/concursos/${item.link}`}
            key={item.link}
            className="w-full sm:w-auto"
            aria-label="Concursos"
          >
            <div className="flex flex-col items-start p-4 rounded-lg border bg-card text-card-foreground shadow-sm w-full h-full">
              <div className="flex flex-row items-start space-x-3">
                <img
                  loading="lazy"
                  src={item.icon}
                  alt="Logo do concurso"
                  width={128}
                  height={128}
                  className="max-h-16 object-contain"
                />
                <h3 className="w-full m-0 font-semibold text-sm text-left overflow-hidden line-clamp-3 text-ellipsis">
                  {item.name}
                </h3>
              </div>
              <div className="flex justify-between items-end w-full mt-8">
                <div className="flex flex-col">
                  <strong className="text-2xl text-sky-900">
                    {item.positions}
                  </strong>
                </div>
                <span className="text-xs font-normal max-w-[70px]">
                  {item.income && <>Salários de {item.income}</>}
                </span>
              </div>
            </div>
          </a>
        ))}
        <div className='flex flex-col items-center justify-center p-4 rounded-lg border bg-card text-card-foreground shadow-sm w-full h-full text-center space-y-3 bg-no-repeat bg-cover bg-[url("https://www.direcaoconcursos.com.br/assets/images/background-card.svg")]'>
          <h2 className="text-sm text-zinc-50">
            Assine agora e tenha acesso completo a todos os nossos cursos
          </h2>
          <Button variant={'default'} asChild>
            <a
              href={SUBSCRIPTION}
              target="_blank"
              aria-label="assine"
              rel="noreferrer"
            >
              Assine
            </a>
          </Button>
        </div>
      </div>
    </section>
  )
}
